import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./views/Home";

// import { LanguageProvider } from "./components/container/Language";

// import { Text } from "./components/languages/Text/Text";

// import SignUp from "./views/SignUp";
// import SignIn from "./views/SignIn";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

function App() {

  return (
    <div>
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
        </Router>
      <NotificationContainer />
    </div>
  );
}

export default App;
