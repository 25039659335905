import React from "react";


function Home() {

  return (
    <div className="">
      <div id="preloader"></div>
      <div className="top-scroll transition">
        <a href="#banner" className="scrollTo">Up</a>
      </div>
      <header className="transition">
        <div className="container">
          <div className="row flex-align">
            <div className="col-lg-4 col-md-3 col-8">
              <div className="logo flex-align">
                <a href="index.html"
                  ><img src="images/logo.png" className="transition" alt="Cryptcon"
                /></a>
                <p className="logo-text">CR7</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-9 col-4 text-right">
              <div className="menu-toggle">
                <span></span>
              </div>
              <div className="menu">
                <ul className="d-inline-block">
                  <li><a href="#tokensale-part">Tokenomics</a></li>
                  <li><a href="#roadmap">Roadmap</a></li>
                  <li><a href="#team">Team</a></li>
                </ul>
                <div className="signin d-inline-block">
                  <a href="#buynow" className="btn">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="home-banner" id="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 position-u flex-align wow fadeInLeft">
              <div className="banner-contain">
                <h1 className="banner-heading">
                  Gateway To Entering the <span>Crypto World!</span> With a Big
                  Giveaway!
                </h1>
                <p className="banner-des">
                  CR7 Marketing is a platform that offers a token for all those
                  who are planning to enter the cryptocurrency and are looking to
                  get more understanding on how the whole system operates.
                </p>
                <a href="docs/whitepaper.pdf" download="" className="btn"
                  >White Paper</a
                >
              </div>
            </div>
            <div className="col-lg-6 col-md-6 position-u wow fadeInRight">
              <div className="banner-img">
                <img src="images/giveaway.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="work-part lightskyblue ptb-100" id="token">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp">
                <div className="section-heading text-center pb-65">
                  <label className="sub-heading">what is CR7 Marketing Token?</label>
                  <h2 className="heading-title">How it Works</h2>
                </div>
            </div>
          </div>
          <div className="row flex-align">
            <div
              className="col-md-6 text-center flex-align justify-center wow fadeInLeft"
            >
              <div className="work-box">
                <div className="work-box-bg"></div>
                <img
                  src="images/work-process.png"
                  className="rotation-img"
                  alt="Work Process"
                />
              </div>
            </div>
            <div className="col-md-6 flex-align wow fadeInRight">
              <div className="work-box">
                <h3 className="work-process-title pb-25">
                  About CR7 Token, Cryptocurrency & NFT
                </h3>
              
                <ul className="check-list">
                  <li>
                    <span></span>
                    <p>
                      CR7 is a very new token created to assist people looking
                      forward to entering in the cryptocurrency & digital assets
                      world. It helps explore the coins available in the market
                      and help you make decisions on trading for the same.
                    </p>
                  </li>
                  <li>
                    <span></span>
                    <p>
                      Cryptocurrency and NFT (non-fungible token) are two related
                      but distinct concepts in the world of digital assets.
                      Cryptocurrency refers to a type of digital currency that
                      uses encryption techniques to regulate the generation of
                      units of currency and verify the transfer of funds,
                      independent of a central bank or government. Bitcoin,
                      Ethereum, and Litecoin are some popular examples of
                      cryptocurrencies.
                    </p>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-part pt-100 pb-10" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp">
              <div className="section-heading text-center pb-65">
                <label className="sub-heading">Opportunity</label>
                <h2 className="heading-title">
                  Opportunity to Make <br />Your Dreams Come True!
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 wow fadeInUp pb-80">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src="images/feature-1.png" alt="Safe & Secure" />
                </div>
                <div className="feature-contain pt-25">
                  <a href="#" className="feature-title">Gateway to Crypto</a>
                  <p className="feature-des">
                    Opportunity to create tokens, market it & wait for its hefty
                    returns.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 wow fadeInUp pb-80">
              <div className="feature-box">
                <div className="feature-icon">
                  <a href="#"
                    ><img src="images/feature-2.png" alt="Early Bonus"
                  /></a>
                </div>
                <div className="feature-contain pt-25">
                  <a href="#" className="feature-title"
                    >Create an Exchange Platform</a
                  >
                  <p className="feature-des">
                    We are working on building an application that helps founders
                    trade their tokens.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 wow fadeInUp pb-80">
              <div className="feature-box">
                <div className="feature-icon">
                  <a href="#"
                    ><img src="images/feature-3.png" alt="Univarsal Access"
                  /></a>
                </div>
                <div className="feature-contain pt-25">
                  <a href="#" className="feature-title">Creating a NFT Collection</a>
                  <p className="feature-des">
                    A Cristiano Ronaldo NFT where coin holders can trade and
                    generate on the platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="timeline lightskyblue ptb-100" id="roadmap">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp">
              <div className="section-heading text-center pb-65">
                <label className="sub-heading">roadmap</label>
                <h2 className="heading-title">The Timeline</h2>
              
              </div>
            </div>
          </div>
          <div className="main-roadmap">
            <div className="row">
              <div className="col-md-12">
                <div className="h-border wow fadeInLeft"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="roadmap-slider owl-carousel">
                  <div className="roadmap wow fadeInLeft">
                    <div className="roadmap-box text-center">
                      <div className="date-title">November 2023</div>
                      <div className="map-graphic">
                        <div className="small-round"><span></span></div>
                        <div className="v-row"></div>
                      </div>
                      <div className="roadmap-detail-box">
                        <p>CR7 Marketing Token Deploy</p>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap wow fadeInLeft">
                    <div className="roadmap-box text-center">
                      <div className="date-title">December 2023</div>
                      <div className="map-graphic">
                        <div className="small-round"><span></span></div>
                        <div className="v-row"></div>
                      </div>
                      <div className="roadmap-detail-box">
                        <p>Official Website Development, Token ICO launch</p>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap wow fadeInLeft">
                    <div className="roadmap-box text-center">
                      <div className="date-title">January 2024</div>
                      <div className="map-graphic">
                        <div className="small-round"><span></span></div>
                        <div className="v-row"></div>
                      </div>
                      <div className="roadmap-detail-box">
                        <p>Dex Listing, Token Staking</p>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap wow fadeInLeft">
                    <div className="roadmap-box text-center">
                      <div className="date-title">March 2024</div>
                      <div className="map-graphic">
                        <div className="small-round"><span></span></div>
                        <div className="v-row"></div>
                      </div>
                      <div className="roadmap-detail-box">
                        <p>Dex, Dapp Development</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team-part pt-100 pb-55" id="team">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp">
              <div className="section-heading text-center pb-65">
                <label className="sub-heading">meet the team</label>
                <h2 className="heading-title">Our Team</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 wow fadeInLeft pb-45">
              <div className="team-box flex-align">
                <div className="team-img">
                  <a href="team.html"
                    ><img src="images/team.jpg" alt="team member"
                  /></a>
                </div>
                <div className="team-des">
                  <a href="team.html" className="member-name">Muhammed Salim</a>
                  <p className="member-des">Founder</p>
                  <ul className="pt-15">
                    <li>
                      <a href="#"
                        ><i className="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-linkedin" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 pb-45 wow fadeInRight">
              <div className="team-box flex-align">
                <div className="team-img">
                  <a href="team.html"
                    ><img src="images/team.jpg" alt="team member"
                  /></a>
                </div>
                <div className="team-des">
                  <a href="team.html" className="member-name">Bilal Magid</a>
                  <p className="member-des">Website management</p>
                  <ul className="pt-15">
                    <li>
                      <a href="#"
                        ><i className="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-linkedin" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeInLeft pb-45">
              <div className="team-box flex-align">
                <div className="team-img">
                  <a href="team.html"
                    ><img src="images/team.jpg" alt="team member"
                  /></a>
                </div>
                <div className="team-des">
                  <a href="team.html" className="member-name">Saleha Al Saeedi</a>
                  <p className="member-des">Social Media Management</p>
                  <ul className="pt-15">
                    <li>
                      <a href="#"
                        ><i className="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-linkedin" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 pb-45 wow fadeInRight">
              <div className="team-box flex-align">
                <div className="team-img">
                  <a href="team.html"
                    ><img src="images/team.jpg" alt="team member"
                  /></a>
                </div>
                <div className="team-des">
                  <a href="team.html" className="member-name"
                    >Jordan from Alt Signals</a
                  >
                  <p className="member-des">Marketing Agent</p>
                  <ul className="pt-15">
                    <li>
                      <a href="#"
                        ><i className="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-linkedin" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="token-sale parallax lightskyblue ptb-100"
        id="tokensale-part"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft flex-align">
              <div className="w-100">
                <div className="section-heading pb-20">
                  <label className="sub-heading">tokenomics</label>
                  <h2 className="heading-title">Token Sale</h2>
                  </div>
                <div className="token-graphic-detail">
                  <ul>
                    <li className="color-code-1">73% Finacial Overhead</li>
                    <li className="color-code-2">55% Bonus & found</li>
                    <li className="color-code-3">12% Gift Code Inventory</li>
                    <li className="color-code-4">32% Bounty and Overhead</li>
                    <li className="color-code-5">38% it infastrueture</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 flex-align justify-center-r">
              <div className="token-graph w-100">
                <div className="donut">
                  <div data-lcolor="#f8c04e">12.2</div>
                  <div data-lcolor="#ac56f7">32.6</div>
                  <div data-lcolor="#61f89f">38.2</div>
                  <div data-lcolor="#5ad6f8">55.2</div>
                  <div data-lcolor="#f85d77">73.2</div>
                </div>
                <div className="graph-logo">
                  <img src="images/logo.png" className="cryptoz" alt="cryptoz" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    

      <section className="ico-apps parallax-2 pt-100 pb-100" id="process">
        <div className="container">
          <div className="row flex-align">
            <div className="col-lg-6 wow fadeInLeft flex-bottom order-r-2">
              <div className="ico-apps-img w-100 text-center">
                <img src="images/goldenboot.png" alt="mobile apps" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight pb-100 order-r-1 pl-32">
              <div className="section-heading pb-20">
                <label className="sub-heading">Process</label>
                <h2 className="heading-title">
                  Mega Giveaway of $25,000 or a Golden Boot
                </h2>
                <p className="heading-des pb-20">
                  In 3 Easy Steps You Can Enter the Giveaway.
                </p>

                <ul className="check-list mb-30">
                  <li>
                    <span></span>
                    <p>1. Purchase $100 Token</p>
                  </li>
                  <li>
                    <span></span>
                    <p>2. Follow Us on All Social Media Handles</p>
                  </li>
                  <li>
                    <span></span>
                    <p>3. Share Us on Your Story</p>
                  </li>
                </ul>
              
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ico-apps parallax-2 pt-100 pb-100" id="buynow">
        <div className="container">
          <div className="row flex-align responsive-flex">
            <div className="col-lg-6 col-sm-12  fadeInRight pb-100 order-r-1 pl-32">
              <div className="section-heading pb-20">
                <label className="sub-heading">Token Buy</label>
                <h2 className="heading-title">
                  Buy your favorite crypto at the price you want
                </h2>
                <p className="heading-des pb-20">
                  Using CR7 Tokens, you can start working on the dream of owning a
                  cryptocurrency and trading it to make a fortune.
                </p>

                <ul className="check-list mb-30">
                  <li>
                    <span></span>
                    <p>1. Tether Bep20 (BSC)</p>
                  </li>
                  <li>
                    <span></span>
                    <p>2. BNB (BSC)</p>
                  </li>
                  <li>
                    <span></span>
                    <p>3. ApplyPay</p>
                  </li>
                </ul>
                
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 wow fadeInLeft flex-bottom order-r-2">
              <form className="border p-20" id="tokenbuyform">
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label"
                    >Token Address</label
                  >
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
            value="0xce6944102c4fae93faa65088ad53785c142aa75c"
            readonly
                  />
                  <div id="emailHelp" className="form-text" >
                    Click address to copy it.
                    </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label"
                    >Token Status (0.2% SOLD)</label
                  >
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
            value="50 / 100000 Sold"
            readonly
                  />
                
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label"
                    >Token Amount</label
                  >
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label"
                    >Payment Method</label
                  >
                  <select className="form-select form-control" aria-label="Default select example">
                    
                    <option value="1">Tether Bep20</option>
                    <option value="2">BNB</option>
                    <option value="3">ApplePay</option>
                  </select>
                </div>

                <button type="submit" className="btn btn-primary">Confirm</button>
              </form>
            </div>
          </div>
        </div>
      </section>

    

      <footer className="bg-pattern lightskyblue ptb-100">
        <div className="container">
          <div className="footer">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-logo pb-25">
                  <a href="index.html"
                    ><img src="images/logo.png" alt="Cryptcon"
                  /></a>
                </div>
                <div className="footer-icon">
                  <ul>
                    <li>
                      <a href="#"
                        ><i className="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-linkedin" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        ><i className="fa fa-instagram" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer-link">
                  <ul>
                  
                  
                    <li><a href="#tokensale-part">Tokenomics</a></li>
                    <li>
                      <a href="docs/whitepaper.pdf" download="">Whitepaper</a>
                    </li>
                  
                    <li><a href="#roadmap">Roadmap</a></li>
                    <li><a href="#team">Teams</a></li>
                  
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="subscribe">
                  <div className="form-group">
                    <label>Subscribe to our Newsleter</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email Address"
                    />
                    <input
                      type="submit"
                      name="submit"
                      value="Subscribe"
                      className="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="row">
              <div className="col-lg-6">
                <p>© CR7 Marketing all Rights Reserved.</p>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li><a href="#">Terms & Condition</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>     
    </div>
  );
}

export default Home;

